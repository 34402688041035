// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
// import Turbolinks from "turbolinks"
import "channels";
require("@rails/ujs").start();
// Turbolinks.start()
require("@rails/activestorage").start();
require("turbolinks").start();
require("trix");
require("@rails/actiontext");
require("packs/segment_analytics.js");
require("packs/quill-editor.js");
require("packs/rich-text.js");
//= require jquery
//= require popper
//= require turbolinks
//= require bootstrap
//= require_tree.
