/*!
 * Quill Resize Module v1.2.4
 * https://github.com/mudoo/quill-resize-module
 */
!(function (t, e) {
  "object" == typeof exports && "object" == typeof module ? (module.exports = e(require("quill"))) 
  : "function" == typeof define && define.amd ? define(["Quill"], e) 
  : "object" == typeof exports ? (exports.QuillResize = e(require("Quill"))) 
  : (t.QuillResize = e(t.Quill));
})(window, function (t) {
  return (function (t) {
    var e = {};
    function i(o) {
      if (e[o]) return e[o].exports;
      var n = (e[o] = { i: o, l: !1, exports: {} });
      return t[o].call(n.exports, n, n.exports, i), (n.l = !0), n.exports;
    }
    return (
      (i.m = t),
      (i.c = e),
      (i.d = function (t, e, o) {
        i.o(t, e) || Object.defineProperty(t, e, { enumerable: !0, get: o });
      }),
      (i.r = function (t) {
        "undefined" != typeof Symbol && Symbol.toStringTag && Object.defineProperty(t, Symbol.toStringTag, { value: "Module" }), Object.defineProperty(t, "__esModule", { value: !0 });
      }),
      (i.t = function (t, e) {
        if ((1 & e && (t = i(t)), 8 & e)) return t;
        if (4 & e && "object" == typeof t && t && t.__esModule) return t;
        var o = Object.create(null);
        if ((i.r(o), Object.defineProperty(o, "default", { enumerable: !0, value: t }), 2 & e && "string" != typeof t))
          for (var n in t)
            i.d(
              o,
              n,
              function (e) {
                return t[e];
              }.bind(null, n)
            );
        return o;
      }),
      (i.n = function (t) {
        var e =
          t && t.__esModule
            ? function () {
                return t.default;
              }
            : function () {
                return t;
              };
        return i.d(e, "a", e), e;
      }),
      (i.o = function (t, e) {
        return Object.prototype.hasOwnProperty.call(t, e);
      }),
      (i.p = ""),
      i((i.s = 2))
    );
  })([
    function (e, i) {
      e.exports = t;
    },
    function (t, e, i) {
      "use strict";
      e.__esModule = !0;
      var o = (function () {
        function t(e) {
          !(function (t, e) {
            if (!(t instanceof e)) throw new TypeError("Cannot call a class as a function");
          })(this, t),
            (this.resizer = e),
            (this.quill = e.quill),
            (this.overlay = e.overlay),
            (this.activeEle = e.activeEle),
            (this.blot = e.blot),
            (this.options = e.options),
            (this.requestUpdate = function () {
              e.onUpdate(!0);
            });
        }
        return (t.prototype.onCreate = function () {}), (t.prototype.onDestroy = function () {}), (t.prototype.onUpdate = function () {}), t;
      })();
      e.default = o;
    },
    function (t, e, i) {
      "use strict";
      (e.__esModule = !0), (e.PlaceholderRegister = e.Image = e.Resize = e.convertPlaceholderHTML = e.ClassNamePlaceholder = e.TagPlaceholder = e.EmbedPlaceholder = void 0);
      var o = i(3);
      Object.defineProperty(e, "EmbedPlaceholder", {
        enumerable: !0,
        get: function () {
          return o.EmbedPlaceholder;
        },
      }),
        Object.defineProperty(e, "TagPlaceholder", {
          enumerable: !0,
          get: function () {
            return o.TagPlaceholder;
          },
        }),
        Object.defineProperty(e, "ClassNamePlaceholder", {
          enumerable: !0,
          get: function () {
            return o.ClassNamePlaceholder;
          },
        }),
        Object.defineProperty(e, "convertPlaceholderHTML", {
          enumerable: !0,
          get: function () {
            return o.convertPlaceholderHTML;
          },
        });
      var n = a(i(0));
      i(16);
      var r = a(i(4)),
        s = i(15),
        l = a(o);
      function a(t) {
        return t && t.__esModule ? t : { default: t };
      }
      (window.Quill || n.default).register(s.Image, !0),
        (e.default = r.default),
        (e.Resize = r.default),
        (e.Image = s.Image),
        (e.PlaceholderRegister = l.default),
        Element.prototype.matches || (Element.prototype.matches = Element.prototype.msMatchesSelector || Element.prototype.webkitMatchesSelector),
        Element.prototype.closest ||
          (Element.prototype.closest = function (t) {
            var e = this;
            if (!document.documentElement.contains(e)) return null;
            do {
              if (e.matches(t)) return e;
              e = e.parentElement || e.parentNode;
            } while (null !== e && 1 === e.nodeType);
            return null;
          });
    },
    function (t, e, i) {
      "use strict";
      (e.__esModule = !0), (e.convertPlaceholderHTML = e.ClassNamePlaceholder = e.TagPlaceholder = e.EmbedPlaceholder = void 0);
      var o =
        Object.assign ||
        function (t) {
          for (var e = 1; e < arguments.length; e++) {
            var i = arguments[e];
            for (var o in i) Object.prototype.hasOwnProperty.call(i, o) && (t[o] = i[o]);
          }
          return t;
        };
      e.default = function () {
        var t = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : [v];
        Array.isArray(t) || (t = [t]);
        t.push(m),
          t.forEach(function (t) {
            h.register(t, !0), (t.tagName = y.tagName), (t.className = m.className);
          });
      };
      var n,
        r = i(0),
        s = (n = r) && n.__esModule ? n : { default: n };
      function l(t, e) {
        if (!(t instanceof e)) throw new TypeError("Cannot call a class as a function");
      }
      function a(t, e) {
        if (!t) throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
        return !e || ("object" != typeof e && "function" != typeof e) ? t : e;
      }
      function u(t, e) {
        if ("function" != typeof e && null !== e) throw new TypeError("Super expression must either be null or a function, not " + typeof e);
        (t.prototype = Object.create(e && e.prototype, { constructor: { value: t, enumerable: !1, writable: !0, configurable: !0 } })), e && (Object.setPrototypeOf ? Object.setPrototypeOf(t, e) : (t.__proto__ = e));
      }
      var h = window.Quill || s.default,
        c = h.import("blots/container"),
        d = h.import("blots/scroll"),
        p = ["data-embed-source", "data-type", "data-src", "data-size", "style"],
        f = h.import("parchment"),
        y = (function (t) {
          function e() {
            return l(this, e), a(this, t.apply(this, arguments));
          }
          return (
            u(e, t),
            (e.create = function (e) {
              var i = t.create.call(this);
              if ("string" == typeof e) i.setAttribute(p[0], e);
              else for (var o in e) Object.prototype.hasOwnProperty.call(e, o) && i.setAttribute(o, e[o]);
              return i.setAttribute("contenteditable", !1), i.setAttribute("unselectable", "on"), i;
            }),
            (e.formats = function (t) {
              return t.__handling && t.__formats
                ? t.__formats
                : p.slice(3).reduce(function (e, i) {
                    return t.hasAttribute(i) && (e[i] = t.getAttribute(i)), e;
                  }, {});
            }),
            (e.value = function (t) {
              var e = p.slice(0, 3),
                i = {};
              return (
                e.forEach(function (e) {
                  var o = "";
                  if (t.hasAttribute(e)) o = t.getAttribute(e);
                  else
                    switch (e) {
                      case p[0]:
                        o = encodeURIComponent(t.outerHTML);
                        break;
                      case p[1]:
                        o = t.tagName;
                        break;
                      case p[2]:
                        o = t.getAttribute("src");
                        break;
                      case "style":
                        o = t.style.cssText;
                        break;
                      default:
                        o = t[e] || "";
                    }
                  o && (i[e] = o);
                }),
                i
              );
            }),
            (e.prototype.format = function (e, i) {
              "style" !== e ? (-1 !== p.indexOf(e) ? (i ? this.domNode.setAttribute(e, i) : this.domNode.removeAttribute(e)) : t.prototype.format.call(this, e, i)) : (this.domNode.style.cssText = i);
            }),
            (e.prototype.handling = function (t) {
              (this.domNode.__formats = this.constructor.formats(this.domNode)), (this.domNode.__handling = t);
            }),
            e
          );
        })(h.import("blots/block/embed"));
      (y.blotName = "embed-placeholder"), (y.tagName = "span"), (y.scope = f.Scope.INLINE_BLOT), c.allowedChildren.push(y), d.allowedChildren.push(y);
      var v = (function (t) {
        function e() {
          return l(this, e), a(this, t.apply(this, arguments));
        }
        return u(e, t), e;
      })(y);
      v.tagName = ["video", "iframe"];
      var m = (function (t) {
        function e() {
          return l(this, e), a(this, t.apply(this, arguments));
        }
        return u(e, t), e;
      })(y);
      m.className = "ql-embed-placeholder";
      var b = /<([\w-]+)((?:\s+[\w-:.]*(?:\s*=\s*(?:(?:"[^"]*")|(?:'[^']*')|[^>\s]+))?)*)\s*>([^<]*?)<\/\1>/g,
        g = /([\w-:.]+)(?:\s*=\s*(?:"((?:\\.|[^"])*)"|'((?:\\.|[^'])*)'))?/g;
      function w(t) {
        var e = {};
        return (
          t.replace(g, function (t, i, o, n) {
            var r = (o || n || "").trim();
            e[i] = r;
          }),
          e
        );
      }
      var E = /<([\w-]+)((?:\s+[\w-:.]*(?:\s*=\s*(?:(?:"[^"]*")|(?:'[^']*')|[^>\s]+))?)*)\s*>/;
      function x() {
        var t = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : "",
          e = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : {};
        return t.replace(E, function (t, i) {
          var n = arguments.length > 2 && void 0 !== arguments[2] ? arguments[2] : "",
            r = w(n);
          o(r, e);
          var s = Object.keys(r).reduce(function (t, e) {
            var i = r[e];
            return null == i ? t : (t += "" === i ? " " + e : " " + e + '="' + i + '"');
          }, "");
          return "<" + i + s + ">";
        });
      }
      (e.EmbedPlaceholder = y),
        (e.TagPlaceholder = v),
        (e.ClassNamePlaceholder = m),
        (e.convertPlaceholderHTML = function () {
          var t = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : "";
          if (!t) return "";
          var e = new RegExp('class\\s*=\\s*(?:"[^"]*\\b(' + m.className + ")\\b[^\"]*\"|'[^']*\\b(" + m.className + ")\\b[^']*')");
          return t.replace(b, function (t, i) {
            var o = arguments.length > 2 && void 0 !== arguments[2] ? arguments[2] : "";
            if (!i || i.toLowerCase() !== y.tagName || !e.test(o)) return t;
            var n = w(o),
              r = decodeURIComponent(n[p[0]]);
            return x(r, { style: n.style, "data-size": n["data-size"] });
          });
        });
    },
    function (t, e, i) {
      "use strict";
      e.__esModule = !0;
      var o =
          Object.assign ||
          function (t) {
            for (var e = 1; e < arguments.length; e++) {
              var i = arguments[e];
              for (var o in i) Object.prototype.hasOwnProperty.call(i, o) && (t[o] = i[o]);
            }
            return t;
          },
        n = h(i(5)),
        r = h(i(6)),
        s = h(i(7)),
        l = h(i(13)),
        a = h(i(14)),
        u = h(i(0));
      function h(t) {
        return t && t.__esModule ? t : { default: t };
      }
      function c(t, e) {
        if (!(t instanceof e)) throw new TypeError("Cannot call a class as a function");
      }
      var d = (window.Quill || u.default).import("parchment"),
        p = { DisplaySize: r.default, Toolbar: s.default, Resize: l.default, Keyboard: a.default },
        f = (function () {
          function t(e) {
            var i = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : {};
            c(this, t), (e.resizer = this), (this.quill = e);
            var r = !1;
            i.modules && (r = i.modules.slice()), (this.options = o({}, n.default, i)), (this.options.styles = o({}, n.default.styles, i.styles)), !1 !== r && (this.options.modules = r), document.execCommand("enableObjectResizing", !1, "false"), this.quill.root.addEventListener("mousedown", this.handleClick.bind(this), !1), this.quill.on("text-change", this.handleChange.bind(this)), this.quill.emitter.on("resize-edit", this.handleEdit.bind(this)), (this.quill.root.parentNode.style.position = this.quill.root.parentNode.style.position || "relative"), (this.selectedBlots = []), this.options.selectedClass && this.quill.on("selection-change", this.addBlotsSelectedClass.bind(this)), (this.moduleClasses = this.options.modules), (this.modules = []), this.options.keyboardSelect && a.default.injectInit(this.quill);
          }
          return (
            (t.prototype.initializeModules = function () {
              var t = this;
              this.removeModules(),
                (this.modules = this.moduleClasses.map(function (e) {
                  return new (p[e] || e)(t);
                })),
                this.modules.forEach(function (t) {
                  t.onCreate();
                }),
                this.onUpdate();
            }),
            (t.prototype.onUpdate = function (t) {
              (this.updateFromModule = t),
                this.repositionElements(),
                this.modules.forEach(function (t) {
                  t.onUpdate();
                });
            }),
            (t.prototype.removeModules = function () {
              this.modules.forEach(function (t) {
                t.onDestroy();
              }),
                (this.modules = []);
            }),
            (t.prototype.handleEdit = function () {
              if (this.blot) {
                var t = this.blot.offset(this.quill.scroll);
                this.hide(), this.quill.focus(), this.quill.setSelection(t, 1);
              }
            }),
            (t.prototype.handleClick = function (t) {
              if (this.quill.isEnabled()) { //check to see if quill is enabled
                var e = !1,
                  i = void 0,
                  o = t.target;
                o && o.tagName && (i = this.quill.constructor.find(o)) && (e = this.judgeShow(i, o)), e ? t.preventDefault() : this.activeEle && this.hide();
              }
            }),
            (t.prototype.judgeShow = function (t, e) {
              var i = !1;
              if (!t) return i;
              !e && t.domNode && (e = t.domNode);
              var o = this.options.parchment[t.statics.blotName];
              if (!o) return i;
              if (this.activeEle === e) return !0;
              var n = o.limit || {};
              return (!n.minWidth || (n.minWidth && e.offsetWidth >= n.minWidth)) && ((i = !0), this.activeEle && this.hide(), (this.activeEle = e), (this.blot = t), this.show(e)), i;
            }),
            (t.prototype.handleChange = function (t, e, i) {
              this.updateFromModule ? (this.updateFromModule = !1) : "user" === i && this.overlay && this.activeEle && this.onUpdate();
            }),
            (t.prototype.show = function () {
              this.showOverlay(), this.initializeModules(), this.options.activeClass && this.activeEle.classList.add(this.options.activeClass);
            }),
            (t.prototype.showOverlay = function () {
              var t = this;
              this.overlay && this.hideOverlay(),
                this.quill.setSelection(null),
                this.setUserSelect("none"),
                (this.overlay = document.createElement("div")),
                o(this.overlay.style, this.options.styles.overlay),
                this.overlay.addEventListener("dblclick", this.handleEdit.bind(this), !1),
                this.quill.root.parentNode.appendChild(this.overlay),
                (this.hideProxy = function (e) {
                  t.activeEle && t.hide();
                }),
                this.quill.root.addEventListener("input", this.hideProxy, !0),
                (this.updateOverlayPositionProxy = this.updateOverlayPosition.bind(this)),
                this.quill.root.addEventListener("scroll", this.updateOverlayPositionProxy),
                this.repositionElements();
            }),
            (t.prototype.hideOverlay = function () {
              this.overlay && (this.quill.root.parentNode.removeChild(this.overlay), (this.overlay = void 0), document.removeEventListener("keydown", this.keyboardProxy, !0), this.quill.root.removeEventListener("input", this.hideProxy, !0), this.quill.root.removeEventListener("scroll", this.updateOverlayPositionProxy), this.setUserSelect(""));
            }),
            (t.prototype.repositionElements = function () {
              if (this.overlay && this.activeEle) {
                var t = this.quill.root.parentNode,
                  e = this.activeEle.getBoundingClientRect(),
                  i = t.getBoundingClientRect();
                o(this.overlay.style, { left: e.left - i.left - 1 + t.scrollLeft + "px", top: e.top - i.top + this.quill.root.scrollTop + "px", width: e.width + "px", height: e.height + "px", marginTop: -1 * this.quill.root.scrollTop + "px" });
              }
            }),
            (t.prototype.updateOverlayPosition = function () {
              this.overlay.style.marginTop = -1 * this.quill.root.scrollTop + "px";
            }),
            (t.prototype.addBlotsSelectedClass = function (t, e) {
              var i = this;
              if (!t) return this.removeBlotsSelectedClass(), void (this.selectedBlots = []);
              var o = this.quill.scroll.descendants(d.Leaf, t.index, t.length).filter(function (t) {
                var e = !!i.options.parchment[t.statics.blotName];
                return e && t.domNode.classList.add(i.options.selectedClass), e;
              });
              this.removeBlotsSelectedClass(o), (this.selectedBlots = o);
            }),
            (t.prototype.removeBlotsSelectedClass = function () {
              var t = this,
                e = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : [];
              Array.isArray(e) || (e = [e]),
                this.selectedBlots.forEach(function (i) {
                  -1 === e.indexOf(i) && i.domNode.classList.remove(t.options.selectedClass);
                });
            }),
            (t.prototype.hide = function () {
              this.hideOverlay(), this.removeModules(), this.activeEle && this.options.activeClass && this.activeEle.classList.remove(this.options.activeClass), (this.activeEle = void 0), (this.blot = void 0);
            }),
            (t.prototype.setUserSelect = function (t) {
              var e = this;
              ["userSelect", "mozUserSelect", "webkitUserSelect", "msUserSelect"].forEach(function (i) {
                (e.quill.root.style[i] = t), (document.documentElement.style[i] = t);
              });
            }),
            t
          );
        })();
      (e.default = f), window.Quill && window.Quill.register("modules/resize", f);
    },
    function (t, e, i) {
      "use strict";
      (e.__esModule = !0), (e.default = { modules: ["DisplaySize", "Toolbar", "Resize", "Keyboard"], keyboardSelect: !0, selectedClass: "selected", activeClass: "active", parchment: { image: { attribute: ["width"], limit: { minWidth: 100 } }, "embed-placeholder": { attribute: ["width", "height"], limit: { minWidth: 200, ratio: 0.5625 } }, video: { attribute: ["width", "height"], limit: { minWidth: 200, ratio: 0.5625 } } }, styles: { overlay: { position: "absolute", boxSizing: "border-box", border: "1px dashed #444" }, handle: { position: "absolute", height: "12px", width: "12px", backgroundColor: "white", border: "1px solid #777", boxSizing: "border-box", opacity: "0.80" }, display: { position: "absolute", padding: "4px 8px", textAlign: "center", backgroundColor: "white", color: "#333", border: "1px solid #777", boxSizing: "border-box", opacity: "0.80", cursor: "default", lineHeight: "1" }, toolbar: { position: "absolute", top: "-12px", right: "0", left: "0", height: "0", minWidth: "120px", textAlign: "center", color: "#333", boxSizing: "border-box", cursor: "default" }, toolbarButton: { display: "inline-block", width: "24px", height: "24px", background: "white", border: "1px solid #999", verticalAlign: "middle" }, toolbarButtonSvg: {} } });
    },
    function (t, e, i) {
      "use strict";
      e.__esModule = !0;
      var o,
        n =
          Object.assign ||
          function (t) {
            for (var e = 1; e < arguments.length; e++) {
              var i = arguments[e];
              for (var o in i) Object.prototype.hasOwnProperty.call(i, o) && (t[o] = i[o]);
            }
            return t;
          },
        r = i(1);
      function s(t, e) {
        if (!(t instanceof e)) throw new TypeError("Cannot call a class as a function");
      }
      function l(t, e) {
        if (!t) throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
        return !e || ("object" != typeof e && "function" != typeof e) ? t : e;
      }
      var a = (function (t) {
        function e() {
          return s(this, e), l(this, t.apply(this, arguments));
        }
        return (
          (function (t, e) {
            if ("function" != typeof e && null !== e) throw new TypeError("Super expression must either be null or a function, not " + typeof e);
            (t.prototype = Object.create(e && e.prototype, { constructor: { value: t, enumerable: !1, writable: !0, configurable: !0 } })), e && (Object.setPrototypeOf ? Object.setPrototypeOf(t, e) : (t.__proto__ = e));
          })(e, t),
          (e.prototype.onCreate = function () {
            (this.display = document.createElement("div")), n(this.display.style, this.options.styles.display), this.overlay.appendChild(this.display);
          }),
          (e.prototype.onUpdate = function () {
            if (this.display && this.activeEle) {
              var t = this.getCurrentSize();
              if (((this.display.innerHTML = t.join(" &times; ")), t[0] > 120 && t[1] > 30)) n(this.display.style, { right: "4px", bottom: "4px", left: "auto" });
              else if ("right" === this.activeEle.style.float) {
                var e = this.display.getBoundingClientRect();
                n(this.display.style, { right: "auto", bottom: "-" + (e.height + 4) + "px", left: "-" + (e.width + 4) + "px" });
              } else {
                var i = this.display.getBoundingClientRect();
                n(this.display.style, { right: "-" + (i.width + 4) + "px", bottom: "-" + (i.height + 4) + "px", left: "auto" });
              }
            }
          }),
          (e.prototype.getCurrentSize = function () {
            return [this.activeEle.offsetWidth, this.activeEle.offsetHeight];
          }),
          e
        );
      })(((o = r) && o.__esModule ? o : { default: o }).default);
      e.default = a;
    },
    function (t, e, i) {
      "use strict";
      e.__esModule = !0;
      var o =
          Object.assign ||
          function (t) {
            for (var e = 1; e < arguments.length; e++) {
              var i = arguments[e];
              for (var o in i) Object.prototype.hasOwnProperty.call(i, o) && (t[o] = i[o]);
            }
            return t;
          },
        n = c(i(1)),
        r = c(i(8)),
        s = c(i(9)),
        l = c(i(10)),
        a = c(i(11)),
        u = c(i(12)),
        h = c(i(0));
      function c(t) {
        return t && t.__esModule ? t : { default: t };
      }
      function d(t, e) {
        if (!(t instanceof e)) throw new TypeError("Cannot call a class as a function");
      }
      function p(t, e) {
        if (!t) throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
        return !e || ("object" != typeof e && "function" != typeof e) ? t : e;
      }
      var f = (window.Quill || h.default).import("parchment"),
        y = new (f.ClassAttributor ? f.ClassAttributor : f.Attributor.Class)("imagestyle", "ql-resize-style"),
        v = (function (t) {
          function e() {
            return d(this, e), p(this, t.apply(this, arguments));
          }
          return (
            (function (t, e) {
              if ("function" != typeof e && null !== e) throw new TypeError("Super expression must either be null or a function, not " + typeof e);
              (t.prototype = Object.create(e && e.prototype, { constructor: { value: t, enumerable: !1, writable: !0, configurable: !0 } })), e && (Object.setPrototypeOf ? Object.setPrototypeOf(t, e) : (t.__proto__ = e));
            })(e, t),
            (e.prototype.onCreate = function () {
              (this.toolbar = document.createElement("div")), o(this.toolbar.style, this.options.styles.toolbar), this.overlay.appendChild(this.toolbar), this._defineAlignments(), this._addToolbarButtons();
            }),
            (e.prototype._defineAlignments = function () {
              var t = this;
              this.alignments = [
                {
                  icon: r.default,
                  apply: function () {
                    y.add(t.activeEle, "left");
                  },
                  isApplied: function () {
                    return "left" === y.value(t.activeEle);
                  },
                },
                {
                  icon: s.default,
                  apply: function () {
                    y.add(t.activeEle, "center");
                  },
                  isApplied: function () {
                    return "center" === y.value(t.activeEle);
                  },
                },
                {
                  icon: l.default,
                  apply: function () {
                    y.add(t.activeEle, "right");
                  },
                  isApplied: function () {
                    return "right" === y.value(t.activeEle);
                  },
                },
                {
                  icon: a.default,
                  apply: function () {
                    y.add(t.activeEle, "full");
                  },
                  isApplied: function () {
                    return "full" === y.value(t.activeEle);
                  },
                },
              ];
            }),
            (e.prototype._addToolbarButtons = function () {
              var t = this,
                e = [];
              this.alignments.forEach(function (i, n) {
                var r = document.createElement("span");
                e.push(r),
                  (r.innerHTML = i.icon),
                  r.addEventListener("click", function () {
                    e.forEach(function (t) {
                      return (t.style.filter = "");
                    }),
                      i.isApplied() ? y.remove(t.activeEle) : (t._selectButton(r), i.apply()),
                      t.requestUpdate();
                  }),
                  o(r.style, t.options.styles.toolbarButton),
                  n > 0 && (r.style.borderLeftWidth = "0"),
                  o(r.children[0].style, t.options.styles.toolbarButtonSvg),
                  i.isApplied() && t._selectButton(r),
                  t.toolbar.appendChild(r);
              });
              var i = document.createElement("span");
              (i.innerHTML = u.default),
                o(i.style, this.options.styles.toolbarButton),
                (i.style.borderLeftWidth = "0"),
                i.addEventListener("click", function () {
                  t.quill.emitter.emit("resize-edit", t.activeEle, t.blot);
                }),
                this.toolbar.appendChild(i);
            }),
            (e.prototype._selectButton = function (t) {
              t.style.filter = "invert(20%)";
            }),
            e
          );
        })(n.default);
      e.default = v;
    },
    function (t, e, i) {
      "use strict";
      i.r(e), (e.default = '<svg viewbox="0 0 18 18">\n  <path class="ql-fill" d="M15,8H13a1,1,0,0,1,0-2h2A1,1,0,0,1,15,8Z"/>\n  <path class="ql-fill" d="M15,12H13a1,1,0,0,1,0-2h2A1,1,0,0,1,15,12Z"/>\n  <path class="ql-fill" d="M15,16H5a1,1,0,0,1,0-2H15A1,1,0,0,1,15,16Z"/>\n  <path class="ql-fill" d="M15,4H5A1,1,0,0,1,5,2H15A1,1,0,0,1,15,4Z"/>\n  <rect class="ql-fill" x="2" y="6" width="8" height="6" rx="1" ry="1"/>\n</svg>');
    },
    function (t, e, i) {
      "use strict";
      i.r(e), (e.default = '<svg viewbox="0 0 18 18">\n  <path class="ql-fill" d="M14,16H4a1,1,0,0,1,0-2H14A1,1,0,0,1,14,16Z"/>\n  <path class="ql-fill" d="M14,4H4A1,1,0,0,1,4,2H14A1,1,0,0,1,14,4Z"/>\n  <rect class="ql-fill" x="3" y="6" width="12" height="6" rx="1" ry="1"/>\n</svg>');
    },
    function (t, e, i) {
      "use strict";
      i.r(e), (e.default = '<svg viewbox="0 0 18 18">\n  <path class="ql-fill" d="M5,8H3A1,1,0,0,1,3,6H5A1,1,0,0,1,5,8Z"/>\n  <path class="ql-fill" d="M5,12H3a1,1,0,0,1,0-2H5A1,1,0,0,1,5,12Z"/>\n  <path class="ql-fill" d="M13,16H3a1,1,0,0,1,0-2H13A1,1,0,0,1,13,16Z"/>\n  <path class="ql-fill" d="M13,4H3A1,1,0,0,1,3,2H13A1,1,0,0,1,13,4Z"/>\n  <rect class="ql-fill" x="8" y="6" width="8" height="6" rx="1" ry="1" transform="translate(24 18) rotate(-180)"/>\n</svg>');
    },
    function (t, e, i) {
      "use strict";
      i.r(e), (e.default = '<svg viewbox="0 0 18 18">\n  <path class="ql-fill" d="M13,16H5a1,1,0,0,1,0-2h8A1,1,0,0,1,13,16Z"/>\n  <path class="ql-fill" d="M13,4H5A1,1,0,0,1,5,2h8A1,1,0,0,1,13,4Z"/>\n  <rect class="ql-fill" x="2" y="6" width="14" height="6" rx="1" ry="1"/>\n</svg>');
    },
    function (t, e, i) {
      "use strict";
      i.r(e), (e.default = '<svg viewBox="0 0 18 18">\n  <path class="ql-fill" d="M 12.9 2 L 11.3 3.6 L 14.8 7 L 16.3 5.5 L 12.9 2 Z M 9.3 5.5 L 2 12.2 L 2 15.5 L 5.7 15.5 L 13 8.9 L 9.3 5.5 Z"></path>\n</svg>\n');
    },
    function (t, e, i) {
      "use strict";
      e.__esModule = !0;
      var o,
        n =
          Object.assign ||
          function (t) {
            for (var e = 1; e < arguments.length; e++) {
              var i = arguments[e];
              for (var o in i) Object.prototype.hasOwnProperty.call(i, o) && (t[o] = i[o]);
            }
            return t;
          },
        r = i(1);
      function s(t, e) {
        if (!(t instanceof e)) throw new TypeError("Cannot call a class as a function");
      }
      function l(t, e) {
        if (!t) throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
        return !e || ("object" != typeof e && "function" != typeof e) ? t : e;
      }
      var a = (function (t) {
        function e() {
          return s(this, e), l(this, t.apply(this, arguments));
        }
        return (
          (function (t, e) {
            if ("function" != typeof e && null !== e) throw new TypeError("Super expression must either be null or a function, not " + typeof e);
            (t.prototype = Object.create(e && e.prototype, { constructor: { value: t, enumerable: !1, writable: !0, configurable: !0 } })), e && (Object.setPrototypeOf ? Object.setPrototypeOf(t, e) : (t.__proto__ = e));
          })(e, t),
          (e.prototype.onCreate = function () {
            (this.boxes = []), this.addBox("nwse-resize"), this.addBox("nesw-resize"), this.addBox("nwse-resize"), this.addBox("nesw-resize"), this.positionBoxes();
          }),
          (e.prototype.onDestroy = function () {
            this.setCursor("");
          }),
          (e.prototype.positionBoxes = function () {
            var t = this,
              e = -parseFloat(this.options.styles.handle.width) / 2 + "px",
              i = -parseFloat(this.options.styles.handle.height) / 2 + "px";
            [
              { left: e, top: i },
              { right: e, top: i },
              { right: e, bottom: i },
              { left: e, bottom: i },
            ].forEach(function (e, i) {
              n(t.boxes[i].style, e);
            });
          }),
          (e.prototype.addBox = function (t) {
            var e = document.createElement("div");
            n(e.style, this.options.styles.handle), (e.style.cursor = t), (e.style.width = this.options.styles.handle.width + "px"), (e.style.height = this.options.styles.handle.height + "px"), e.addEventListener("mousedown", this.handleMousedown.bind(this), !1), this.overlay.appendChild(e), this.boxes.push(e);
          }),
          (e.prototype.handleMousedown = function (t) {
            var e = this;
            this.blot.handling && this.blot.handling(!0),
              (this.dragBox = t.target),
              (this.dragStartX = t.clientX),
              (this.dragStartY = t.clientY),
              (this.preDragSize = { width: this.activeEle.offsetWidth, height: this.activeEle.offsetHeight }),
              (this.naturalSize = this.getNaturalSize()),
              this.setCursor(this.dragBox.style.cursor),
              (this.handleDragProxy = function (t) {
                return e.handleDrag(t);
              }),
              (this.handleMouseupProxy = function (t) {
                return e.handleMouseup(t);
              }),
              document.addEventListener("mousemove", this.handleDragProxy, !1),
              document.addEventListener("mouseup", this.handleMouseupProxy, !1);
          }),
          (e.prototype.handleMouseup = function () {
            this.setCursor(""), this.blot.handling && this.blot.handling(!1), document.removeEventListener("mousemove", this.handleDragProxy), document.removeEventListener("mouseup", this.handleMouseupProxy);
          }),
          (e.prototype.handleDrag = function (t) {
            var e = this;
            if (this.activeEle && this.blot) {
              var i = t.clientX - this.dragStartX,
                o = t.clientY - this.dragStartY,
                r = this.options.parchment[this.blot.statics.blotName],
                s = {},
                l = 1;
              (r.attribute || ["width"]).forEach(function (t) {
                s[t] = e.preDragSize[t];
              }),
                (this.dragBox !== this.boxes[0] && this.dragBox !== this.boxes[3]) || (l = -1),
                s.width && (s.width = Math.round(this.preDragSize.width + i * l)),
                s.height && (s.height = Math.round(this.preDragSize.height + o * l)),
                n(this.activeEle.style, this.calcSize(s, r.limit)),
                this.requestUpdate();
            }
          }),
          (e.prototype.calcSize = function (t) {
            var e = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : {},
              i = t.width,
              o = t.height;
            if (e.ratio) {
              var n = void 0;
              e.minWidth && (i = Math.max(e.minWidth, i)), e.maxWidth && (i = Math.min(e.maxWidth, i)), (o = i * e.ratio), e.minHeight && o < e.minHeight && ((n = !0), (o = e.minHeight)), e.maxHeight && o > e.maxHeight && ((n = !0), (o = e.maxHeight)), n && (i = o / e.ratio);
            } else t.width && (e.minWidth && (i = Math.max(e.minWidth, i)), e.maxWidth && (i = Math.min(e.maxWidth, i))), t.height && (e.minHeight && (o = Math.max(e.minHeight, o)), e.maxHeight && (o = Math.min(e.maxHeight, o)));
            return i && (t.width = i + "px"), o && (t.height = o + "px"), t;
          }),
          (e.prototype.getNaturalSize = function () {
            var t = this.activeEle,
              e = [0, 0];
            return t.getAttribute("data-size") ? (e = t.getAttribute("data-size").split(",")) : ((e = [t.naturalWidth || t.offsetWidth, t.naturalHeight || t.offsetHeight]), t.setAttribute("data-size", e[0] + "," + e[1])), { width: parseInt(e[0]), height: parseInt(e[1]) };
          }),
          (e.prototype.setCursor = function (t) {
            [document.body, this.activeEle].forEach(function (e) {
              e.style.cursor = t;
            });
          }),
          e
        );
      })(((o = r) && o.__esModule ? o : { default: o }).default);
      e.default = a;
    },
    function (t, e, i) {
      "use strict";
      e.__esModule = !0;
      var o,
        n = i(1);
      function r(t, e) {
        if (!(t instanceof e)) throw new TypeError("Cannot call a class as a function");
      }
      function s(t, e) {
        if (!t) throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
        return !e || ("object" != typeof e && "function" != typeof e) ? t : e;
      }
      var l = (function (t) {
        function e() {
          return r(this, e), s(this, t.apply(this, arguments));
        }
        return (
          (function (t, e) {
            if ("function" != typeof e && null !== e) throw new TypeError("Super expression must either be null or a function, not " + typeof e);
            (t.prototype = Object.create(e && e.prototype, { constructor: { value: t, enumerable: !1, writable: !0, configurable: !0 } })), e && (Object.setPrototypeOf ? Object.setPrototypeOf(t, e) : (t.__proto__ = e));
          })(e, t),
          (e.injectInit = function (t) {
            var e = t.keyboard.bindings;
            e[this.keys.LEFT].unshift(this.makeArrowHandler(this.keys.LEFT, !1)), e[this.keys.RIGHT].unshift(this.makeArrowHandler(this.keys.RIGHT, !1));
          }),
          (e.makeArrowHandler = function (t, i) {
            var o,
              n = t === e.keys.LEFT ? "prefix" : "suffix";
            return (
              ((o = { key: t, shiftKey: i, altKey: null })[n] = /^$/),
              (o.handler = function (i) {
                if (!this.quill.resizer) return !0;
                var o = i.index,
                  n = this.quill.getLine(i.index)[0],
                  r = this.quill.getIndex(n);
                if (t === e.keys.RIGHT && r + n.length() - 1 === o) return !0;
                t === e.keys.RIGHT && (o += i.length + 1);
                var s = this.quill.getLeaf(o)[0],
                  l = s.offset(s.parent);
                return (t === e.keys.LEFT && (0 === o || o === r)) || (t === e.keys.LEFT && 0 === l && ((o -= 1), (s = this.quill.getLeaf(o)[0])), !this.quill.resizer.judgeShow(s));
              }),
              o
            );
          }),
          (e.prototype.onCreate = function (t) {
            var e = this;
            (this.keyboardProxy = function (t) {
              return e.keyboardHandle(t);
            }),
              document.addEventListener("keydown", this.keyboardProxy, !0);
          }),
          (e.prototype.onDestroy = function () {
            document.removeEventListener("keydown", this.keyboardProxy, !0);
          }),
          (e.prototype.keyboardHandle = function (t) {
            if (!t.defaultPrevented && !(t.shiftKey || t.ctrlKey || t.altKey) && this.activeEle && !t.fromResize && !t.ctrlKey) {
              var i = t.keyCode,
                o = this.blot.offset(this.quill.scroll),
                n = void 0,
                r = !1;
              i === e.keys.BACKSPACE || i === e.keys.DELETE ? (this.blot.deleteAt(0), this.blot.parent.optimize(), (r = !0)) : i >= e.keys.LEFT && i <= e.keys.DOWN && (i === e.keys.RIGHT ? o++ : i === e.keys.UP ? ((o = this.getOtherLineIndex(-1)), (n = this.quill.getLeaf(o)[0])) : i === e.keys.DOWN && ((o = this.getOtherLineIndex(1)), (n = this.quill.getLeaf(o)[0])), (r = !0)), r && (t.stopPropagation(), t.preventDefault()), (n && this.resizer.judgeShow(n, n.domNode)) || (this.quill.setSelection(o), this.resizer.hide());
            }
          }),
          (e.prototype.getOtherLineIndex = function (t) {
            var e = this.blot.offset(this.quill.scroll),
              i = this.quill.getLine(e)[0],
              o = this.blot.offset(i) + 1,
              n = t > 0 ? i.next : i.prev;
            if (n) {
              var r = n.length();
              "block" === n.statics.blotName && r--, (e = n.offset(this.quill.scroll) + Math.min(r, o));
            }
            return e;
          }),
          (e.prototype.dispatchEvent = function (t) {
            var e = new t.constructor(t);
            (e.fromResize = !0), this.quill.root.dispatchEvent(e);
          }),
          e
        );
      })(((o = n) && o.__esModule ? o : { default: o }).default);
      (e.default = l), (l.keys = { BACKSPACE: 8, TAB: 9, ENTER: 13, ESCAPE: 27, LEFT: 37, UP: 38, RIGHT: 39, DOWN: 40, DELETE: 46 });
    },
    function (t, e, i) {
      "use strict";
      (e.__esModule = !0), (e.ATTRIBUTES = e.Image = void 0);
      var o,
        n = i(0),
        r = (o = n) && o.__esModule ? o : { default: o };
      function s(t, e) {
        if (!(t instanceof e)) throw new TypeError("Cannot call a class as a function");
      }
      function l(t, e) {
        if (!t) throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
        return !e || ("object" != typeof e && "function" != typeof e) ? t : e;
      }
      var a = window.Quill || r.default,
        u = ["alt", "height", "width", "style", "data-size"],
        h = (function (t) {
          function e() {
            return s(this, e), l(this, t.apply(this, arguments));
          }
          return (
            (function (t, e) {
              if ("function" != typeof e && null !== e) throw new TypeError("Super expression must either be null or a function, not " + typeof e);
              (t.prototype = Object.create(e && e.prototype, { constructor: { value: t, enumerable: !1, writable: !0, configurable: !0 } })), e && (Object.setPrototypeOf ? Object.setPrototypeOf(t, e) : (t.__proto__ = e));
            })(e, t),
            (e.formats = function (t) {
              return t.__handling && t.__formats
                ? t.__formats
                : u.reduce(function (e, i) {
                    return t.hasAttribute(i) && (e[i] = t.getAttribute(i)), e;
                  }, {});
            }),
            (e.prototype.format = function (e, i) {
              u.indexOf(e) > -1 ? (i ? this.domNode.setAttribute(e, i) : this.domNode.removeAttribute(e)) : t.prototype.format.call(this, e, i);
            }),
            (e.prototype.handling = function (t) {
              (this.domNode.__formats = this.constructor.formats(this.domNode)), (this.domNode.__handling = t);
            }),
            e
          );
        })(a.import("formats/image"));
      (e.Image = h), (e.ATTRIBUTES = u);
    },
    function (t, e) {},
  ]);
});
