import {QuillEditors} from 'packs/quill-editor';

$(document).on("submit", "form", (event) => {
  const richTextEditors = $('.smart-form-rich-text');
  const container = $(richTextEditors[0]);
  const quillContent = container.find(".smart-form-rich-text-quill-content");
  const editorContainer = container.find(".smart-form-rich-text-editor");
  const content = QuillEditors.extractFromEditor(editorContainer[0]);
  quillContent.val(JSON.stringify(content));
	console.log("SUBMIT", event);
});

$(document).on("turbolinks:load", (event) => {	
	const richTextEditors = $('.smart-form-rich-text');
	
	richTextEditors.each((index) => {
		const container = $(richTextEditors[index]);
		const fieldName = container.attr('name');
		
		const quillContent = container.find(".smart-form-rich-text-quill-content");
		
		const editorContainer = container.find(".smart-form-rich-text-editor");
		const fieldPlaceholder = editorContainer.attr('placeholder');
		
		let existingContent = undefined;
		const ops = container.find(".smart-form-rich-text-quill-ops"); 
		if (ops.length > 0) {
			existingContent = JSON.parse(ops.text());
		}
		
		const editor = QuillEditors.createEditor(editorContainer[0], existingContent, fieldPlaceholder);

		const content = QuillEditors.extractFromEditor(editorContainer[0]);
		quillContent.val(JSON.stringify(content));

		editor.on('text-change', function(delta, oldDelta, source) {
			const content = QuillEditors.extractFromEditor(editorContainer[0], true);
			quillContent.val(JSON.stringify(content));
			quillContent.trigger('keyup');
		});
	});
  
});