document.addEventListener("turbolinks:load", function () {
  console.log("segment_analytics.js loaded");

  const currentPathName = window.location.pathname;
  const currentUrl = window.location.href;
  var email = $(".temp_information").data("email");
  if (email === undefined) {
    email = "unknown";
  }
  // Landing on Setup Guide Page
  if (currentPathName === "/setup_guide") {
    var data = {
      event: "Listener - SetupGuide",
      properties: {
        path: currentPathName,
        email: email,
      },
    };

    $.ajax({
      url: "/segment_analytics/track_page",
      type: "POST",
      data: JSON.stringify(data),
      contentType: "application/json",
    });
    // Landing on Returns Page
  } else if (currentPathName.includes("/shop-all")) {
    var data = {
      event: "Listener - ShopAll",
      properties: {
        path: currentPathName,
        email: email,
      },
    };

    $.ajax({
      url: "/segment_analytics/track_page",
      type: "POST",
      data: JSON.stringify(data),
      contentType: "application/json",
    });
  } else if (currentPathName.includes("/sellers")) {
    var data = {
      event: "Listener - Sellers",
      properties: {
        path: currentPathName,
        email: email,
      },
    };

    $.ajax({
      url: "/segment_analytics/track_page",
      type: "POST",
      data: JSON.stringify(data),
      contentType: "application/json",
    });
  } else if (currentUrl.includes("/returns") && !currentUrl.includes("?") && !currentUrl.includes("confirmed")) {
    var data = {
      event: "Listener - Returns",
      properties: {
        path: currentPathName,
        company: currentPathName.split("/")[2],
        email: email,
      },
    };

    $.ajax({
      url: "/segment_analytics/track_page",
      type: "POST",
      data: JSON.stringify(data),
      contentType: "application/json",
    });
  } else if (currentUrl.includes("/returns") && !currentUrl.includes("?") && currentUrl.includes("confirmed")) {
    var data = {
      event: "Listener - ReturnsConfirmed",
      properties: {
        path: currentPathName,
        company: currentPathName.split("/")[2],
        email: email,
      },
    };

    $.ajax({
      url: "/segment_analytics/track_page",
      type: "POST",
      data: JSON.stringify(data),
      contentType: "application/json",
    });
    // Landing on Home Page
  } else if (currentPathName === "/" && !currentUrl.includes("?")) {
    const homePage = document.getElementById("home-page");
    if (homePage) {
      var data = {
        event: "Listener - HomePage",
        properties: {
          path: currentPathName,
          email: email,
        },
      };

      $.ajax({
        url: "/segment_analytics/track_page",
        type: "POST",
        data: JSON.stringify(data),
        contentType: "application/json",
      });
    }
    //Landing on Product Page
  } else if ( currentPathName !== "/products" && currentPathName.includes("/products") && !currentPathName.includes("purchase") && !currentPathName.includes("edit") && !currentPathName.includes("merchant")) {
    console.log(currentPathName);
    var data = {
      event: "Listener - Products",
      properties: {
        path: currentPathName,
        product_id: currentPathName.split("/")[3],
        email: email,
      },
    };

    $.ajax({
      url: "/segment_analytics/track_page",
      type: "POST",
      data: JSON.stringify(data),
      contentType: "application/json",
    });

    //Clicked Next to View Next Page of Products
  } else if (currentPathName === "/" && currentUrl.includes("?page")) {
    var data = {
      event: "Listener - NextPage",
      properties: {
        path: currentPathName,
        page_number: currentUrl.includes("?page") ? currentUrl.split("=")[1] : 1,
        email: email,
      },
    };

    $.ajax({
      url: "/segment_analytics/track_page",
      type: "POST",
      data: JSON.stringify(data),
      contentType: "application/json",
    });
  }

  //Clicked on Create Hafback Merchant Account Now Button
  const createMerchantAccountButton = document.getElementById("create-merchant-account");
  if (createMerchantAccountButton) {
    createMerchantAccountButton.addEventListener("click", function () {
      var data = {
        event: "Merchants - CreateMerchantAccount",
        properties: {
          path: currentPathName,
          email: email,
        },
      };

      $.ajax({
        url: "/segment_analytics/track_event",
        type: "POST",
        data: JSON.stringify(data),
        contentType: "application/json",
      });
    });
  }
  //Merchant or User Clicked on Add Phone Number Button
  const addPhoneNumberButton = document.getElementById("add-phone-number");
  if (addPhoneNumberButton) {
    addPhoneNumberButton.addEventListener("click", function () {
      var data = {
        event: "ConfirmAccount - AddPhoneNumber",
        properties: {
          path: currentPathName,
          email: email,
        },
      };

      $.ajax({
        url: "/segment_analytics/track_event",
        type: "POST",
        data: JSON.stringify(data),
        contentType: "application/json",
      });
    });
  }
  //Clicked on Confirm Phone Token Button
  const confirmPhoneTokenButton = document.getElementById("confirm-phone-token");
  if (confirmPhoneTokenButton) {
    confirmPhoneTokenButton.addEventListener("click", function () {
      var data = {
        event: "ConfirmAccount - ConfirmPhoneToken",
        properties: {
          path: currentPathName,
          email: email,
        },
      };

      $.ajax({
        url: "/segment_analytics/track_event",
        type: "POST",
        data: JSON.stringify(data),
        contentType: "application/json",
      });
    });
  }
  //Clicked on save company button
  const saveCompanyButton = document.getElementById("save-company");
  if (saveCompanyButton) {
    saveCompanyButton.addEventListener("click", function () {
      var data = {
        event: "Company - CreateCompany",
        properties: {
          path: currentPathName,
          email: email,
        },
      };

      $.ajax({
        url: "/segment_analytics/track_event",
        type: "POST",
        data: JSON.stringify(data),
        contentType: "application/json",
      });
    });
  }

  //Clicked on Connect Shopify Store Button
  const connectShopifyStoreButton = document.getElementById("connect-shopify-store");
  if (connectShopifyStoreButton) {
    connectShopifyStoreButton.addEventListener("click", function () {
      var data = {
        event: "Company - ConnectShopifyStore",
        properties: {
          path: currentPathName,
          company_slug: currentPathName.split("/")[2],
          email: email,
        },
      };

      $.ajax({
        url: "/segment_analytics/track_event",
        type: "POST",
        data: JSON.stringify(data),
        contentType: "application/json",
      });
    });
  }

  //Clicked on Save Collection Changes Button
  const saveCollectionChangesButton = document.querySelector(".collections-settings-save-button");
  if (saveCollectionChangesButton) {
    saveCollectionChangesButton.addEventListener("click", function () {
      var data = {
        event: "CollectionSettings - SaveCollectionSettings",
        properties: {
          path: currentPathName,
          email: email,
        },
      };

      $.ajax({
        url: "/segment_analytics/track_event",
        type: "POST",
        data: JSON.stringify(data),
        contentType: "application/json",
      });
    });
  }
  //Clicked on Sync Shopify Store Button
  const syncShopifyStoreButton = document.getElementById("sync-shopify-store");
  if (syncShopifyStoreButton) {
    syncShopifyStoreButton.addEventListener("click", function () {
      var data = {
        event: "Company - SyncShopifyStore",
        properties: {
          path: currentPathName,
          company_slug: currentPathName.split("/")[2],
          email: email,
        },
      };

      $.ajax({
        url: "/segment_analytics/track_event",
        type: "POST",
        data: JSON.stringify(data),
        contentType: "application/json",
      });
    });
  }
  //Clicked Search Order on Returns Page
  const searchOrderButton = document.getElementById("search-order");
  if (searchOrderButton) {
    searchOrderButton.addEventListener("click", function () {
      var data = {
        event: "Returns - OrderLookUp",
        properties: {
          path: currentPathName,
          order_number: document.getElementById("order_id").value,
          company_shopify_name: currentPathName.split("/")[2],
          email: email,
        },
      };

      $.ajax({
        url: "/segment_analytics/track_event",
        type: "POST",
        data: JSON.stringify(data),
        contentType: "application/json",
      });
    });
  }
  //Clicked on Buy Now on Product Page
  const buyNowButton = document.getElementById("product-buy-now");
  if (buyNowButton) {
    buyNowButton.addEventListener("click", function () {
      var data = {
        event: "Product - BuyNow",
        properties: {
          path: currentPathName,
          product_id: currentPathName.split("/")[3],
          email: email,
        },
      };

      $.ajax({
        url: "/segment_analytics/track_event",
        type: "POST",
        data: JSON.stringify(data),
        contentType: "application/json",
      });
    });
  }
  // //Clicked Next to Confirm Order Summary
  // const confirmOrderSummaryButton = document.getElementById("check-out");
  // if (confirmOrderSummaryButton) {
  //   confirmOrderSummaryButton.addEventListener("click", function() {
  //     data = {
  //       event: "Product - ConfirmOrderSummary",
  //       properties: {
  //         path: currentPathName,
  //         product_id: currentPathName.split("/")[3],
  //         email: email,
  //       },
  //     };
  //     fetch('/segment_analytics/track_event', {
  //       method: 'POST',
  //       body: JSON.stringify(data),
  //       headers: {
  //         "Content-Type": "application/json",
  //         "X-CSRF-Token": document.querySelector("meta[name='csrf-token']").getAttribute("content"),
  //       }
  //     });
  //   });
  // }

  //Clicked Notify me when back in stock button
  const notifyMeButton = document.querySelector(".notify-me-btn");
  if (notifyMeButton) {
    notifyMeButton.addEventListener("click", function () {
      var data = {
        event: "Product - NotifyBackInStock",
        properties: {
          path: currentPathName,
          product_id: currentPathName.split("/")[3],
          email: email,
        },
      };

      $.ajax({
        url: "/segment_analytics/track_event",
        type: "POST",
        data: JSON.stringify(data),
        contentType: "application/json",
      });
    });
  }

  //Clicked a Brand to filter Product Results
  const brandButton = document.querySelectorAll("[id='brand']");
  if (brandButton) {
    brandButton.forEach(function (button) {
      button.addEventListener("click", function () {
        var data = {
          event: "HomePage - BrandSelected",
          properties: {
            path: currentPathName,
            brand: button.innerText,
            email: email,
          },
        };

        $.ajax({
          url: "/segment_analytics/track_event",
          type: "POST",
          data: JSON.stringify(data),
          contentType: "application/json",
        });
      });
    });
  }

  //Searched a Query on HomePage
  const searchButton = document.getElementById("search-product");
  if (searchButton) {
    searchButton.addEventListener("click", function () {
      var data = {
        event: "HomePage - Search",
        properties: {
          path: currentPathName,
          query: document.getElementById("product_searcher").value,
          email: email,
        },
      };

      $.ajax({
        url: "/segment_analytics/track_event",
        type: "POST",
        data: JSON.stringify(data),
        contentType: "application/json",
      });
    });
  }

  const searchInput = document.getElementById("product_searcher");
  if (searchInput) {
    searchInput.addEventListener("keyup", function (e) {
      if (e.key === "Enter") {
        var data = {
          event: "HomePage - Search",
          properties: {
            path: currentPathName,
            query: document.getElementById("product_searcher").value,
            email: email,
          },
        };

        $.ajax({
          url: "/segment_analytics/track_event",
          type: "POST",
          data: JSON.stringify(data),
          contentType: "application/json",
        });
      }
    });
  }
});
